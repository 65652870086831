import React, { useEffect } from 'react';

export default function KuulaProject() {

	useEffect(() => {
		// console.log('window', window)
	}, []);

	return (
		<div>
			<iframe className="ku-embed" 
				title="Tillavision.com"
				frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullscreen 
				src="https://tours.mindpower.dev/share/collection/7ZSN7?logo=-1&info=0&fs=1&vr=1&thumbs=-1"></iframe>
		</div>
	)

}

