import React, { useEffect } from 'react';

import KuulaProject from './components/KuulaProject';

import './styles/App.css';

var frame_id = 0;
const KuulaPlayerAPI = window.KuulaPlayerAPI;

export default function App() {

	useEffect(() => {
		
		KuulaPlayerAPI.addEventListener("frameloaded", function(e) {
			frame_id = e.frame;
		});

	}, []);

	return (
		<div className="App">
			<KuulaProject />
		</div>
	)
}
 